import React, {Component} from 'react'
import {Title, Text} from '../Text'
import {StepsList, StepsDetailsList, photoMap} from './content'

import styled from 'styled-components';

const car = require('../SectionAboutUs/teslaS.png');
const appleStore = require('./appleStore.png');
const googlePlay = require('./googlePlay.png');
const back = require('./back.svg');
const next = require('./next.svg');

const Wrapper = styled.div`
width: 100%;
min-height: 100%;
overflow-x: hidden;
padding: 50px 50px 100px;

@media (max-width: 1023px) {
min-height: auto;
padding: 50px 0 50px 9px;
}
@media (max-width: 550px) {
margin-bottom: 25px;
}
`;

const Container = styled.div`
max-width: 1440px;
margin: 0 auto;

position: relative;
height: 805px;
min-height: 805px;

display: flex;
align-items: flex-start;
justify-content: space-between;

@media (max-width: 1023px) {
height: auto;
min-height: auto;
align-items: flex-end;
}
`;

const Content = styled.div`
width: 53%;
height: 100%;
display: flex;
flex-direction: column;
align-items: flex-start;
justify-content: space-between;

& > span {
max-width: 350px;
}

@media (max-width: 1100px) {
width: 58%;
}

@media (max-width: 1023px) {
width: 51%;
justify-content: flex-start;

& > span {
max-width: 100%;
min-width: 90vw;
}
}

@media (max-width: 550px) {
width: 100%;
}
`;

const ImgBx = styled.div`
position: relative;
width: 32%
height: 100%;
background: #FFB000;

@media (max-width: 1023px) {
width: 40%
height: 80%;
min-height: 805px;
}
@media (max-width: 550px) {
display: none;
}
`;

const MobileImg = styled.div`
position: absolute;
right: 32%;
top: 50%;
width: 35%;
max-width: 435px;
transform: translate(50%, -50%);

& > img {
position: relative;
width: 100%;
height: auto;
}

& > div {
position: absolute;
width: 37%;
height: 90%;
top: 4%;
z-index: 2;
cursor: pointer;

&:before {
position: absolute;
display: block;
content: ' ';
width: 36px;
height: 36px;
top: 50%;
left: 33px;
transform: translate(-50%, -50%);
opacity: 0;
transition: opacity 0.4s;
background-image: url(${back});
}

&:hover {
&:before {
opacity: 1;
}
}

&.left {
right: 50%;
}

&.right {
left: 50%;

&:before {
left: calc(100% - 35px);
background-image: url(${next});
}
}
}

@media (max-width: 1100px) {
right: 28%;
}
@media (max-width: 1023px) {
right: 30%;
top: 60%;
width: 50%;
max-width: 400px;
}
@media (max-width: 550px) {
right: -24px;
transform: translate(0%,0%);
top: auto;
bottom: 0;
width: 60%;

& > div {
display: none;
}
}
`;

const AutoImg = styled.img`
position: absolute;
right: 50%;
top: 50%;
width: 95%;
transform: translate(50%, -50%);
`;

const SubTitle = styled(Text)`
width: 72%
max-width: 450px;
margin-top: 15px;

@media (max-width: 1023px) {
max-width: auto;
margin-top: 25px;
min-width: 503px;
}
@media (max-width: 550px) {
min-width: 90%;
}
`;

const StepsBx = styled.div`
width: 100%;
margin-top: auto;
display: flex;
justify-content: space-between;


@media (max-width: 1200px) {
margin-top: 25px;
}
@media (max-width: 1023px) {
flex-direction: column;
margin-top: 75px;
}
@media (max-width: 550px) {
margin-top: 44px;
}

`;

const StepList = styled.ul`
flex-basis: 30%
min-width: 200px;
padding: 0 0 170px 0;

@media (max-width: 1023px) {
padding: 0 0 50px 10px;
}
@media (max-width: 550px) {
padding: 0 0 40px 10px;
}
`;

const Step = styled.li`
font-size: 16px;
font-family: "Bebas";
color: #7F7F7F;
text-transform: uppercase;
line-height: 1;
margin-bottom: 20px;

&.active {
color: #FFB000;
}

&:hover {
color: #FFB000;
cursor: pointer;
}

@media (max-width: 1023px) {
margin-bottom: 15px;
}
`;

const StepDetails = styled.div`
flex-basis: 70%
padding-left: 10px;

@media (max-width: 1023px) {
min-height: 400px;
}
@media (max-width: 550px) {
width: 100%
min-height: 450px;
}
`;

const StepTitle = styled.span`
display: block;
font-size: 47px;
font-family: "Bebas";
color: rgb(255, 176, 0);
text-transform: uppercase;
line-height: 1;
margin-bottom: 30px;

@media (max-width: 1300px) {
display: block;
font-size: 30px;
}
@media (max-width: 1023px) {
margin-bottom: 25px;
}
`;

const Details = styled(Text)`
position: relative;
margin-bottom: 20px;
transition: height .3s;
color: #7F7F7F;

& > span {
display: block;
}

& span:not(:first-child) {
padding-left: 26px;
}

&::before {
position: absolute;
display: block;
left: -5px;
content: ' ';
width: 2px;
height: 0%;
background: #FFB000;
transition: height .3s;
}

&:hover {
cursor: pointer;
color: #1D1D1D;
}

&.active {
color: #1D1D1D;
}

&.active::before {
position: absolute;
display: block;
left: -5px;
content: ' ';
width: 2px;
height: 100%;
background: #FFB000;
transition: height .3s;
}

@media (max-width: 550px) {
font-size: 13px;
margin-bottom: 18px;
width: 51%

& span:not(:first-child) {
padding-left: 22px;
}
}
`;

const StoreBox = styled.div`
display: flex;
margin-top: 15px;
width: 72%;
max-width: 450px;
`;

class SectionInstructionNew extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeStep: 1,
      activeSubStep: 1,
      touchMoveXStart: null,
      touchMoveXEnd: null,
    };
    this.intervalId = null;
  }


  componentDidMount() {
    this.intervalId = setInterval(() => {
      this.handleChangeSlide('increment')
    }, 3000)
  }

  componentWillUnmount() {
    clearTimeout(this.intervalId)
  }

  componentDidUpdate(prevProps, prevState) {
    const {touchMoveXStart, touchMoveXEnd} = this.state;
    if (touchMoveXStart !== null && touchMoveXEnd !== null) {
      if (touchMoveXStart > touchMoveXEnd) {
        this.handleClickMobile('increment')
      } else if (touchMoveXStart < touchMoveXEnd) {
        this.handleClickMobile('decrement')
      }
      this.setState({
        touchMoveXStart: null,
        touchMoveXEnd: null,
      })
    }
  };

  handleChangeStep = (step) => {
    this.intervalId && clearTimeout(this.intervalId);
    this.setState({
      activeStep: step,
      activeSubStep: 1
    })
  };

  handleChangeSubStep = (subStep) => {
    this.intervalId && clearTimeout(this.intervalId);
    this.setState({
      activeSubStep: subStep
    })
  };

  handleChangeSlide = (action) => {
    const {activeStep, activeSubStep} = this.state;
    const maxStepsLength = Object.keys(StepsList).length;

    if (action === 'increment') {
      if (activeSubStep < StepsDetailsList[activeStep].length) {
        this.setState((prevState) => ({
          activeSubStep: prevState.activeSubStep + 1
        }))
      } else if (activeSubStep === StepsDetailsList[activeStep].length) {
        if (activeStep < maxStepsLength) {
          this.setState((prevState) => ({
            activeStep: prevState.activeStep + 1,
            activeSubStep: 1
          }))
        } else {
          this.setState({
            activeStep: 1,
            activeSubStep: 1
          })
        }
      }
    } else if (action === 'decrement') {
      if (activeSubStep > 1) {
        this.setState((prevState) => ({
          activeSubStep: prevState.activeSubStep - 1
        }))
      } else if (activeSubStep === 1) {
        if (activeStep > 1) {
          this.setState((prevState) => ({
            activeStep: prevState.activeStep - 1,
            activeSubStep: StepsDetailsList[prevState.activeStep - 1].length
          }))
        } else {
          this.setState({
            activeStep: maxStepsLength,
            activeSubStep: StepsDetailsList[maxStepsLength].length
          })
        }
      }
    }

  };

  handleClickMobile = (action) => {
    clearTimeout(this.intervalId);
    this.handleChangeSlide(action)
  };

  handleTouchMove = (e, action) => {
    const touches = e.changedTouches;

    if (action === 'start') {
      this.setState({
        touchMoveXStart: touches[0].screenX
      });
    } else {
      this.setState({
        touchMoveXEnd: touches[0].screenX
      });
    }
  };

  renderSteps = () => Object.keys(StepsList).map(step => (
    <Step
      key={step} className={this.state.activeStep === Number(step) ? 'active' : ''}
      onClick={() => this.handleChangeStep(Number(step))}>
      {`${step}. ${StepsList[step]}`}
    </Step>
  ));

  render() {
    const {activeStep, activeSubStep} = this.state;

    return (
      <Wrapper id='#instruction'>
        <Container>
          <Content>
            <Title>
              Як розпочати користуватися?
            </Title>
            <SubTitle>
              Для реєстрації в мережі електрозарядних станцій завантажте мобільний додаток QuickPower
            </SubTitle>

            <StoreBox>
              <div style={{marginRight: 15}}>
                <a href='https://apps.apple.com/ua/app/quick-power/id1464776595'>
                  <img alt="Download on the App Store" height={32} src={appleStore}/>
                </a>
              </div>
              <div>
                <a href='https://play.google.com/store/apps/details?id=com.quickpowerapp.quickpower'>
                  <img alt='Get it on Google Play' height={32} src={googlePlay}/>
                </a>
              </div>
            </StoreBox>

            <StepsBx>
              <StepList>
                {
                  this.renderSteps()
                }
              </StepList>
              <StepDetails>
                <StepTitle>
                  {
                    `${activeStep}. ${StepsList[activeStep]}`
                  }
                </StepTitle>
                {
                  StepsDetailsList[activeStep].map((item, i) => (
                    <Details key={i}
                             className={activeSubStep === i + 1 ? 'active' : ''}
                             onClick={() => this.handleChangeSubStep(i + 1)}>
                      {
                        item.split('*').length === 1 ?
                          item :
                          item.split('*').map(el => <span key={el}>{el}</span>)
                      }
                    </Details>
                  ))}
              </StepDetails>
            </StepsBx>
          </Content>
          <ImgBx>
            <AutoImg src={car} alt="mobile"/>
          </ImgBx>
          <MobileImg>
            <div className='left'
                 onClick={() => this.handleClickMobile('decrement')}/>
            <img
              src={photoMap[activeStep][activeSubStep]}
              alt="mobile"
              onTouchStart={e => this.handleTouchMove(e, 'start')}
              onTouchEnd={e => this.handleTouchMove(e, 'end')}
            />
            <div className='right'
                 onClick={() => this.handleClickMobile('increment')}/>
          </MobileImg>
        </Container>

      </Wrapper>
    )
  }
}

export default SectionInstructionNew
