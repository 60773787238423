import React from 'react'
import styled from 'styled-components';
import {SecondaryText} from "components/Text"
import CountUp from 'react-countup';

const CardContainer = styled.div`
width: 32%;
min-width: 100px;
padding: 20px 25px 20px 0;

& > span {
font-size: 57px;
font-family: 'Bebas';
color: #FFAE00;
}
`;

const Counter = ({title, end, text}) => (
  <CardContainer>
    <CountUp duration={5} end={end} delay={0}/>
    <SecondaryText>
      {text}
    </SecondaryText>
  </CardContainer>
);

export default Counter
