import React from "react";
import styled from "styled-components";

const bird = require('./bird.png');

const BirdImg = styled.img`
position: absolute;
right: -25px;
bottom: 92px;
width: 70px;
height: auto;

@media (max-width: 1130px) {
right: -28px;
bottom: 82px;
}

@media (max-width: 1023px) {
width: 60px;
bottom: 60px;
}

@media (max-width: 767px) {
width: 55px;
right: -24px;
bottom: 39px;
}

@media (max-width: 361px) {
width: 55px;
right: 0;
left: 155px;
bottom: 40px;
}
`;

const Bird = () => <BirdImg src={bird} alt="bird"/>;

export default Bird
