export const locations = [
    {
        "id": "617fdbc0-14de-41a4-9cbd-57bff542fb7a",
        "name": "Аркадія, Індустріальний",
        "address": "вул. В. Гетьмана, 13",
        "photo_urls": [],
        "location_lat": 50.445612,
        "location_lng": 30.443472,
        "online": true,
        "charge_boxes": [
            {
                "current": "dc",
                "power": 50.0,
                "connector": "CHAdeMO",
                "status": "available",
                "quantity": 1
            },
            {
                "current": "dc",
                "power": 50.0,
                "connector": "Combo",
                "status": "available",
                "quantity": 1
            },
            {
                "current": "ac",
                "power": 22.0,
                "connector": "Type-2",
                "status": "available",
                "quantity": 1
            }
        ]
    },
    {
        "id": "5de1687d-1afe-45aa-8452-956402e3cd2f",
        "name": "Сокар, Броварський",
        "address": "пр. Броварский 53a",
        "photo_urls": [],
        "location_lat": 50.454741,
        "location_lng": 30.605031,
        "online": true,
        "charge_boxes": [
            {
                "current": "dc",
                "power": 22.0,
                "connector": "CHAdeMO",
                "status": "available",
                "quantity": 1
            },
            {
                "current": "dc",
                "power": 22.0,
                "connector": "Combo",
                "status": "available",
                "quantity": 1
            },
            {
                "current": "ac",
                "power": 22.0,
                "connector": "Type-2",
                "status": "available",
                "quantity": 1
            },
        ]
    },
    {
        "id": "9bc7f8c9-c4c6-448b-b31d-1e4e72660e78",
        "name": "Сокар, Бориспіль",
        "address": "тер. Гірської с/р 28.6км",
        "photo_urls": [],
        "location_lat": 50.380583,
        "location_lng": 30.831919,
        "online": true,
        "charge_boxes": [
            {
                "current": "dc",
                "power": 50.0,
                "connector": "CHAdeMO",
                "status": "available",
                "quantity": 1
            },
            {
                "current": "dc",
                "power": 50.0,
                "connector": "Combo",
                "status": "available",
                "quantity": 1
            },
            {
                "current": "ac",
                "power": 22.0,
                "connector": "Type-2",
                "status": "available",
                "quantity": 3
            },
        ]
    },
    {
        "id": "8f811059-cae2-4f75-bcba-c8bb5462e5d2",
        "name": "Сокар, Гатне",
        "address": "вул. Інститутська 4, Гатне",
        "photo_urls": [],
        "location_lat": 50.352027,
        "location_lng": 30.430145,
        "online": true,
        "charge_boxes": [
            {
                "current": "ac",
                "power": 22.0,
                "connector": "Type-2",
                "status": "available",
                "quantity": 2
            },
        ]
    },
    {
        "id": "3a662ed5-7695-4e3b-bc3f-b6ded1a5a451",
        "name": "Бiлла",
        "address": "Михайла Гришка, 3, парковка ТЦ Billa",
        "photo_urls": [],
        "location_lat": 50.397021,
        "location_lng": 30.631722,
        "online": true,
        "charge_boxes": [
            {
                "current": "dc",
                "power": 50.0,
                "connector": "CHAdeMO",
                "status": "available",
                "quantity": 1
            },
            {
                "current": "dc",
                "power": 50.0,
                "connector": "Combo",
                "status": "available",
                "quantity": 1
            },
            {
                "current": "ac",
                "power": 22.0,
                "connector": "Type-2",
                "status": "available",
                "quantity": 1
            }
        ]
    },
    {
        "id": "79772dd2-11b7-4f13-9687-92d162140d45",
        "name": "Аркадія, Осокорки",
        "address": "вул. Срібнокільська, 13",
        "photo_urls": [],
        "location_lat": 50.397986,
        "location_lng": 30.617618,
        "online": true,
        "charge_boxes": [
            {
                "current": "dc",
                "power": 50.0,
                "connector": "CHAdeMO",
                "status": "available",
                "quantity": 1
            },
            {
                "current": "dc",
                "power": 50.0,
                "connector": "Combo",
                "status": "available",
                "quantity": 1
            },
            {
                "current": "ac",
                "power": 22.0,
                "connector": "Type-2",
                "status": "available",
                "quantity": 1
            }
        ]
    },
    {
        "id": "2dcc017e-7458-4eb7-b881-8a83d5ca80c1",
        "name": "ТЦ Городок",
        "address": "Степана Бандери 23а",
        "photo_urls": [
            "https://s3.eu-central-1.amazonaws.com/cpms-photo/photo/06b5fd51-cb2c-435c-a9bb-0e4a51d0eae9.jpg"
        ],
        "location_lat": 50.4909717,
        "location_lng": 30.4955802,
        "online": true,
        "charge_boxes": [
            {
                "current": "dc",
                "power": 50.0,
                "connector": "CHAdeMO",
                "status": "available",
                "quantity": 1
            },
            {
                "current": "dc",
                "power": 50.0,
                "connector": "Combo",
                "status": "available",
                "quantity": 1
            },
            {
                "current": "ac",
                "power": 22.0,
                "connector": "Type-2",
                "status": "available",
                "quantity": 5
            }
        ]
    },
    {
        "id": "8adeeac9-790f-496b-960e-2d05dfafd00b",
        "name": "ВІПОС",
        "address": "вул. Дегтярівська, 54",
        "photo_urls": [],
        "location_lat": 50.46331,
        "location_lng": 30.449223,
        "online": true,
        "charge_boxes": [
            {
                "current": "dc",
                "power": 22.0,
                "connector": "CHAdeMO",
                "status": "available",
                "quantity": 1
            },
            {
                "current": "dc",
                "power": 22.0,
                "connector": "Combo",
                "status": "available",
                "quantity": 1
            },
            {
                "current": "ac",
                "power": 22.0,
                "connector": "Type-2",
                "status": "available",
                "quantity": 1
            }
        ]
    },
    {
        "id": "1d24477b-1585-4eed-85b3-c407dde81b99",
        "name": "Фора",
        "address": "вул. Цілинна, 4",
        "photo_urls": [],
        "location_lat": 50.3367368305455,
        "location_lng": 30.5548021202736,
        "online": true,
        "charge_boxes": [
            {
                "connector": "Combo",
                "power": 50.0,
                "current": "dc",
                "status": "available",
                "quantity": 1
            },
            {
                "power": 50.0,
                "current": "dc",
                "connector": "CHAdeMO",
                "status": "available",
                "quantity": 1
            },
            {
                "power": 22.0,
                "current": "ac",
                "connector": "Type-2",
                "status": "available",
                "quantity": 1
            }
        ]
    }
];
