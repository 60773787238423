/*global google*/
import React from 'react'
import styled from "styled-components";
import {compose, withProps, withStateHandlers} from 'recompose'
import {withScriptjs, withGoogleMap, GoogleMap, Marker, InfoWindow} from 'react-google-maps'

import {locations} from "./locations";

const myMapStyles = [{stylers: [{'saturation': -100}, {'gamma': 0.8}, {'lightness': 4}, {'visibility': 'on'}]}];

const pin = require("./pin.png");

const DetailsList = styled.ul`
margin: 0;
padding: 0;
padding-left: 0;

& > li:first-child {
& > span {
font-size: 15px;
font-weight: 400;
}
}

`;

const ListItem = styled.li`
min-width: 175px;
display: flex;
justify-content: flex-start;

& > .address {
margin-bottom: 5px;
}
`;

class MapNew extends React.Component {

  render() {
    const PolylineComponent = compose(
      withProps({
        googleMapURL: "https://maps.googleapis.com/maps/api/js?key=AIzaSyDfhfU1A3Qr6RsHX9hJFVVMMewvQsIHh4Q&v=3.exp&libraries=geometry,drawing,places",
        loadingElement: <div style={{height: `400px`, background: '#E2E2E2'}}/>,
        containerElement: <div style={{width: `100%`, background: '#E2E2E2'}}/>,
        mapElement: <div style={{height: `429px`, width: `100%`}}/>,
      }),
      withScriptjs,
      withGoogleMap,
      withStateHandlers(
        () => ({
          isOpen: false,
          activeId: null
        }), {
          onToggleOpen: ({isOpen, activeId}) => (id) => ({
            isOpen: activeId === null ? !isOpen : activeId === id ? !isOpen : isOpen,
            activeId: activeId === id ? null : id,
          })
        }),
    )(({activeTracker, data, lastPoint, icon, parking, ...props}) =>
      <GoogleMap
        defaultCenter={{
          lat: 50.45,
          lng: 30.523718
        }}
        defaultZoom={10}
        defaultOptions={{
          styles: myMapStyles,
          zoomControl: false,
          mapTypeControl: false,
          scaleControl: false,
          streetViewControl: false,
          rotateControl: false,
          fullscreenControl: true
        }}
      >

        {
          locations.map(el => (
            <Marker
              key={`${el.id}`}
              position={{
                lat: el.location_lat,
                lng: el.location_lng
              }}
              icon={{
                url: pin,
                anchor: new google.maps.Point(30, 60)
              }}
              onClick={() => props.onToggleOpen(el.id)}
            >
              {props.isOpen && props.activeId === el.id &&
              <InfoWindow onCloseClick={props.onToggleOpen}>
                <DetailsList>
                  <ListItem>
                    <span>{el.name}</span>
                  </ListItem>
                  <ListItem>
                    <span className='address'>{el.address}</span>
                  </ListItem>
                  {
                    el.charge_boxes.length > 0 ?
                      el.charge_boxes.map(charge => (
                        <ListItem key={charge.connector}>
                          <span>{`${charge.quantity}x ${charge.connector} / ${charge.power}кВт`}</span>
                        </ListItem>
                      ))
                      : null
                  }
                </DetailsList>
              </InfoWindow>}
            </Marker>
          ))
        }
      </GoogleMap>
    );

    return (
      <PolylineComponent/>
    )
  }
}

export default MapNew
