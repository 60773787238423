import React from 'react'
import {Link} from 'react-scroll';

import {Text} from '../Text'
import SnowFallEffect from '../SnowFallEffect/SnowFallEffect'
import Bird from '../Bird/Bird'
import styled from 'styled-components';

const logo = require('../../containers/Home/logo.png');
const cars = require('./cars_header@2x.png');
const mobile = require('./mobile.png');

const Wrapper = styled.header`
width: 100%;
position: relative;
min-height: calc(625px + 200px);
overflow: hidden;

@media (max-width: 1023px) {
min-height: auto;
}
`;
const Container = styled.div`
max-width: 1440px;
margin: 0 auto;

position: relative;
min-height: 625px;
padding: 23px 52px;
background-image: linear-gradient(35deg, rgb(255,174,0) 0%, rgb(255,215,0) 100%);

@media (max-width: 1023px) {
padding: 18px 6px 250px;
margin-bottom: 160px;
min-height: auto;
}

@media (max-width: 550px) {
margin-bottom: 120px;
}
`;

const Content = styled.div`
width: 100%;
display: flex;
flex-direction: column;
align-items: flex-start;
`;

const Logo = styled.img`
width: 117px;
height: auto;

@media (max-width: 768px) {
width: 113px;
}
`;

const CarsImg = styled.img`
width: 72%;
height: auto;
position: absolute;
left: -60px;
bottom: -115px;

@media (max-width: 1023px) {
width: 90%;
left: -25%;
bottom: -100px;
}
@media (max-width: 767px) {
width: 175%;
max-width: 1100px;
left: -90%;
bottom: -60px;
}
`;

const MobileImg = styled.img`
width: 30%;
height: auto;
position: absolute;
right: 0;
bottom: -15%;

@media (max-width: 2000px) {
max-width: 430px;
bottom: -20%;
}

@media (max-width: 1023px) {
width: 35%;
right: -20px;
bottom: -12%;
max-width: 320px;
}
@media (max-width: 767px) {
width: 50%;
right: -20px;
bottom: -10%;
max-width: 300px;
}
@media (max-width: 545px) {
width: 45%;
}

@media (max-width: 425px) {
width: 50%;
}
`;

const Title = styled.span`
color: #FAF8EC;
font-size: 107px;
line-height: 1.009;
margin: 40px 0 15px;
font-family: 'Bebas';
font-weight: 400;
text-transform: uppercase;
position: relative;

@media (max-width: 1130px) {
font-size: 95px;
}

@media (max-width: 1023px) {
font-size: 70px;
margin: 34px 0 17px;
}

@media (max-width: 767px) {
font-size: 48px;
margin: 27px 0 23px;
}
`;

const SubTitle = styled(Text)`
line-height: 1;
@media (max-width: 767px) {
max-width: 50%;
}
`;

const Navigation = styled.nav`
position: fixed;
right: 0;
top: 50%
padding: 15px;
z-index: 2;
transform: translate(0, -50%);

@media (max-width: 1023px) {
display: none;
}
`;

const NavigationLink = styled(Link)`
display: block;
height: 15px;
width: 15px;
transition: background 0.3s;
background: rgba(0,0,0,0.1);

margin-bottom: 15px;
border-radius: 50%;
  
&:hover {
cursor: pointer;
background: rgba(0,0,0,0.25);
}

&.active {
background: rgba(255,174,0, 0.75);
}
`;

const SectionHeader = () => {
    return (
        <Wrapper id='#header'>
            {/*<SnowFallEffect/>*/}
            <Container>
                <Navigation>
                    <ul>
                        <li title='quick power'>
                            <NavigationLink
                                to="#header"
                                spy={true}
                                smooth={true}
                                duration={500}/>
                        </li>
                        <li title='about us'>
                            <NavigationLink
                                to="#aboutUs"
                                spy={true}
                                smooth={true}
                                duration={500}/>
                        </li>
                        <li title='locations map'>
                            <NavigationLink
                                to="#map"
                                spy={true}
                                smooth={true}
                                duration={500}/>
                        </li>
                       {/* <li title='bonus'>
                            <NavigationLink
                                to="#bonus"
                                spy={true}
                                smooth={true}
                                duration={500}/>
                        </li>*/}
                        <li title='instruction'>
                            <NavigationLink
                                to="#instruction"
                                spy={true}
                                smooth={true}
                                duration={500}/>
                        </li>
                        <li title='contacts'>
                            <NavigationLink
                                to="#footer"
                                spy={true}
                                smooth={true}
                                duration={500}/>
                        </li>
                    </ul>
                </Navigation>
                <Logo src={logo} alt=""/>
                <Content>
                    <Title>
                        Заряджайтесь із<br/> мережею QuickPоwer
                            {/*<Bird/>*/}
                    </Title>
                    <SubTitle>мережа швидкісних зарядних станцій</SubTitle>
                </Content>
                <CarsImg src={cars} alt="electric cars"/>
                <MobileImg src={mobile} alt="mobile"/>
            </Container>
        </Wrapper>
    )
};

export default SectionHeader
