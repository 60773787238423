import React from 'react';
import {NavLink} from 'react-router-dom';
import styled from 'styled-components';
import {Title} from '../Text';

const cars = require('./cars_footer@2x.png');
const logo = require('./logo@2x.png');

const Wrapper = styled.footer`
width: 100%;
min-height: 100vh;
overflow: hidden;

@media (max-width: 1023px) {
min-height: auto;
}
`;
const Container = styled.div`
max-width: 1440px;
margin: 0 auto;

padding: 180px 40px 280px;
display: flex;
justify-content: space-between;

@media (max-width: 1023px) {
padding: 110px 9px 60px;
flex-direction: column;
align-items: flex-start;
}

@media (max-width: 550px) {
padding: 0 0 40px 9px;
}
`;

const ContentWithBg = styled.div`
position: relative;
padding: 29px 83px;
background-color: #252525;
flex-basis: 45%;
min-height: 380px;
display: flex;
flex-direction: column;
align-items: flex-start;
justify-content: space-evenly;
 
@media (max-width: 1023px) {
flex-basis: 76%;
width: 76%;
min-height: auto;
margin-bottom: 150px;
}

@media (max-width: 550px) {
flex-basis: 100%;
width: 100%;
padding: 36px 0 36px 30px;
margin-bottom: 105px;
}
`;

const Content = styled.div`
flex-basis: 35%;
display: flex;
flex-direction: column;
justify-content: space-between;
    
@media (max-width: 1023px) {
padding: 0 18px;
flex-basis: 100%;
width: 100%;
}
@media (max-width: 550px) {
padding: 0;
}
`;

const Logo = styled.img`
width: 242px;
height: auto;
margin-bottom: 34px;

@media (max-width: 550px) {
padding-right: 9px;
align-self: center;
}
`;

const WhiteTitle = styled(Title)`
color: #FAF8EC;
display: block;
margin-bottom: 23px;
`;

const ButtonLink = styled.a`
position: relative;
font-size: 15px;
font-family: "Roboto", sans-serif;
color: #FFFFFF;
line-height: 1.533;
background: #FFB000;
padding: 11px 58px;
margin-bottom: 100px;
transition: background .8s;
z-index: 1;
text-transform: uppercase;

&:hover {
background: inherit;
}

&:hover .top {
transform: scaleX(1);
transform-origin: left;
}
&:hover .right {
transform: scaleY(1);
transform-origin: top;
}
&:hover .bottom {
transform: scaleX(1);
transform-origin: right;
}
&:hover .left {
transform: scaleY(1);
transform-origin: bottom;
}
`;

const Border = styled.span`
position: absolute;
display: block;
background: #FFB000;
transition: transform ease-in-out 0.2s;

&.top {
top: 0;
left: 0;
width: 100%;
height: 1px;
transform: scaleX(0);
transform-origin: right;
transition-delay: 0s;
}

&.right {
top: 0;
right: 0;
width: 1px;
height: 100%;
transform: scaleY(0);
transform-origin: bottom;
transition-delay: 0.2s;
}

&.bottom {
bottom: 0;
left: 0;
width: 100%;
height: 1px;
transform: scaleX(0);
transform-origin: left;
transition-delay: 0.4s;
}

&.left{
top: 0;
left: 0;
width: 1px;
height: 100%;
transform: scaleY(0);
transform-origin: top;
transition-delay: 0.6s;
}
`;

const CarImg = styled.img`
position: absolute;
width: 800px;
bottom: -40%;
left: -35%;

@media (max-width: 1023px) {
width: 650px;
left: auto;
right: -66px;
bottom: -105px;
}

@media (max-width: 550px) {
width: 410px;
left: auto;
right: -40px;
bottom: -55px;
}
`;

const ContactInfoList = styled.ul`
margin-bottom: 34px;

@media (max-width: 1023px) {
padding: 0 5px;
margin-bottom: 46px;
display: flex;
flex-wrap: wrap;
justify-content: space-between;
}
@media (max-width: 550px) {
margin-bottom: 13px;
}

`;

const ContactInfo = styled.li`
font-family: "Roboto", sans-serif;
color: #1D1D1D;
font-size: 15px;
font-weight: 600;
margin-bottom: 34px;

& > span {
font-weight: 400;
}

& > a {
color: #1D1D1D;
font-weight: 400;
}

@media (max-width: 1023px) {
width: 45%;
min-width: 330px;
}

@media (max-width: 550px) {
font-size: 13px;
margin-bottom: 21px;
}
`;

const ContainerLink = styled.div`
position: relative;
padding: 10px 0;
border-top: 1px solid #ffffff;

.top {
transform: scaleX(0);
transform-origin: right;
}

&:hover .top {
//border-top: 1px solid #252525;
transform: scaleX(1);
transform-origin: left;
}
@media (max-width: 1023px) {
width: 100%;
display: flex;
justify-content: center;
}
@media (max-width: 550px) {
width: calc(100% - 9px);
}  

`;

const FooterNavLink = styled(NavLink)`
font-size: 15px!important;
color: #1D1D1D;
line-height: 1;
font-weight: 400;
margin-right: 32px;
//transition: font-weight .3s;
  
&:hover {
//font-weight: 500;
}
@media (max-width: 550px) {
font-size: 12px!important;
}
`;

const SectionFooter = () => {
    return (
        <Wrapper id='#footer'>
            <Container>
                <ContentWithBg>
                    <WhiteTitle>
                        Вхід<br/>
                        для партнерів
                    </WhiteTitle>

                    <ButtonLink href="https://statistic.qp.kiev.ua">
                        увійти
                        <Border className='top'/>
                        <Border className='right'/>
                        <Border className='bottom'/>
                        <Border className='left'/>
                    </ButtonLink>

                    <CarImg src={cars} alt="electric car"/>
                </ContentWithBg>
                <Content>

                    <Logo src={logo} alt="logo"/>

                    <ContactInfoList>
                        <ContactInfo>
                            Адреса: <span>м. Київ, вул. Цілинна 4А, оф. 6, 03131</span>
                        </ContactInfo>
                        <ContactInfo>
                            Служба підтримки: <a href='tel:+38-093-146-7524'>+38(093)146-75-24</a>
                        </ContactInfo>
                        <ContactInfo>
                            E-mail: <a href="mailto:quickpowerua@gmail.com">quickpowerua@gmail.com</a>
                        </ContactInfo>

                    </ContactInfoList>
                    <ContainerLink>
                        <Border className='top'/>
                        <FooterNavLink to="/terms">
                            Terms and Conditions
                        </FooterNavLink>
                    </ContainerLink>
                </Content>
            </Container>
        </Wrapper>
    )
};

export default SectionFooter
