import React, {Component} from 'react';
import {Route, Switch, Redirect} from 'react-router-dom';
import Home from 'containers/Home';
import TermsAndConditionsPage from 'containers/TermsAndConditionsPage';

class App extends Component {
    render() {
        return (
            <div className="App">
                <Switch>
                    <Route exact path="/" component={Home}/>
                    <Route path="/terms" component={TermsAndConditionsPage}/>
                    <Redirect to="/"/>
                </Switch>
            </div>
        );
    }
}

export default App;
