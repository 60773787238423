import React, {Component} from 'react';
import styled from 'styled-components';
import {BlockWrapper, Container} from "components/BlockWrapper"

const TitlePage = styled.span`
font-family: "Roboto", sans-serif;
color: #000000;
font-size: 30px;
margin-bottom: 25px;
display: block;
width: 100%;
text-align: center;

@media (max-width: 550px) {
font-size: 25px;
}
`;

const TextBlackPage = styled.p`
font-family: "Roboto", sans-serif;
font-size: 20px;
margin-bottom: 15px;
font-weight: 400;
text-align: justify;

@media (max-width: 550px) {
font-size: 13px;
}
`;

const SpecificationTextPage = styled.p`
font-family: "Roboto", sans-serif;
color: #000000;
font-size: 22px;
text-align: left;
font-weight: 700;
margin: 10px 0;

@media (max-width: 550px) {
font-size: 18px;
}
`;

class TermsAndConditionsPage extends Component{
    componentDidMount(){
        window.scrollTo({
            top: 0,
            //behavior: "smooth"
        });
    }
    render() {
        return (
            <BlockWrapper style={{borderTop: '1px solid #e0dede'}}>
                <Container style={{justifyContent: 'start', maxWidth: 940}} wrap={'wrap'}>
                    <TextBlackPage>
                        You agree that this PUBLIC AGREEMENT shall come into force for all consumers from June 01, 2019,
                        and shall be obliged to review its terms.
                    </TextBlackPage>
                    <TextBlackPage>
                        The use of the QuickPower Network confirms that you have accepted all terms of this Agreement,
                        the terms of service cost, and the terms of use of the QuickPower chargers.
                    </TextBlackPage>
                    <TitlePage>
                        PUBLIC AGREEMENT
                    </TitlePage>

                    <SpecificationTextPage>
                        1. TERMS AND DEFINITIONS OF THE CONTRACT
                    </SpecificationTextPage>
                    <TextBlackPage>
                        1.1 These General Terms and Conditions apply to all Products and Services, offered and provided
                        by QUICKPOWER to the Consumers. Where indicated, the specific terms and conditions apply to
                        particular types of Products and/or Services.<br/>
                        1.2 These General Terms and Conditions do not apply to products and/or services which are not
                        offered and provided by QUICKPOWER, but by a third party, such as a reseller, in which case the
                        purchase agreement is entered with the third party and not with QUICKPOWER.<br/>
                        1.3. Charge Card: the RFID Charge Card provided by QUICKPOWER that gives access to the use of
                        one or more Charge Points.<br/>
                        1.4 Charge Point: facility or facilities where an Electric Vehicle can be charged using a Charge
                        Card or mobile application QuickPower where the power can be switched on and off by the End
                        User.<br/>
                        1.5 Charging Services: all the services offered to the Customer by QUICKPOWER in connection with
                        the use of a Charge Card or mobile application QuickPower.<br/>
                        Service is the Service of the Performer for charging the batteries of the Customer's Electric
                        Vehicle on the Charging Stations of the Performer, which occurs automatically without the
                        involvement of the Contractor's personnel.
                        1.6 Fee - the amount of the cost of the Services, which is established by the Contractor for the
                        Client for receiving the Services by the Client.<br/>
                        1.7 Electric Vehicle: a road vehicle with more than two wheels that is wholly or partially
                        driven by an electric motor, this vehicle using or not using electricity stored in a battery,
                        chargeable by using a Charge Point.<br/>
                        1.8 Network: QUICKPOWER Network and the Partner Network of the charge points together.<br/>
                        1.9 Partner Network: the combination of all the Charge Points that can be used with the Charge
                        Card or mobile application QuickPower and that are located outside QUICKPOWER Network.<br/>
                        1.10 Portal: the website www.quickpower.net where the owner of the charge point can log in using
                        its login details received from QUICKPOWER as an owner of charge point, for example to manage
                        its usage data and view its consumption.<br/>
                        1.11 Private Charge Point: a Charge Point supplied to the Customer and installed, hosted,
                        maintained and/or managed by QUICKPOWER Network under the Contract that can be used using a
                        Charge Card or mobile applications QuickPower for Public Charging.<br/>
                        1.12 Product: the physical product, including a Charge Point, supplied or to be supplied to the
                        Customer by QUICKPOWER.<br/>
                        1.13 Public Charge Point: a Charge Point in the QUICKPOWER Network that has been opened for use
                        by all Customers with a Charge Card activated for this purpose or mobile application
                        QuickPower.<br/>
                        1.14 Website: the public website www.quickpower.net
                    </TextBlackPage>

                    <SpecificationTextPage>
                        2. Conclusion of the contract
                    </SpecificationTextPage>
                    <TextBlackPage>
                        2.1 The contract between QUICKPOWER and user is concluded by means of registration on the Mobile
                        Application QuickPower and accepting the applicability and validity of these General Terms and
                        Conditions.<br/>
                        2.2 This Terms & Condition is adapted according to continuing further development of our
                        services. Changes to our website will be announced in good time before they are implemented. You
                        should therefore refer to this contract at regular intervals for the latest version.<br/>
                        The contractual term is unlimited and can be terminated with a period of notice to the end of
                        the month. Termination by the customer can be carried out in writing or by email
                        quickpowerua@gmail.com and does not require the statement of reasons.<br/>
                    </TextBlackPage>

                    <SpecificationTextPage>
                        3. Charging services and prices
                    </SpecificationTextPage>
                    <TextBlackPage>
                        Replenishment of the Client Account is carried out solely for the purpose of further receiving
                        the Services in the amount of the balance replenishment.
                        3.1 Replenishment of the Client account on mobile application QuickPower with the help of cards
                        of international payment systems Visa / MasterCard<br/>
                        &nbsp;&nbsp;3.1.1 Replenishment of the Client Account is carried out through the Mobile
                        Application QuickPower by transferring funds from client card Visa/MasterCard to Mobile
                        application balance.<br/>
                        &nbsp;&nbsp;3.1.2. QUICKPOWER does not store information about client cards. All this
                        information stored and provide by third partners with PCI DSS Level 1 certificate.<br/>
                        &nbsp;&nbsp;3.1.3. Replenishment of the Client Account is carried out at the actual cost of the
                        Services, which is displayed on the Mobile Application in the relevant section.<br/>
                        &nbsp;&nbsp;3.1.4. The result of the replenishment of the Client Account is an increase in the
                        volume of pre-paid Services on the Client's account, which occurs immediately after receiving
                        from the payment system confirmation of the successful replenishment of funds from the
                        registered account.<br/>
                        3.2 To start charging client must replenish own balance in mobile application on any amount
                        higher than zero. When client balance reach zero – no charging services will be delivered.<br/>
                        &nbsp;&nbsp;3.2.1. During charging client balance on the mobile application lower on the amount
                        kW/h electric vehicle charged by charge point<br/>
                        &nbsp;&nbsp;3.2.2 If Charging by charge point was interrupt, unused fund will be saved on client
                        account balance<br/>
                    </TextBlackPage>

                    <SpecificationTextPage>
                        4. Return/Refund
                    </SpecificationTextPage>
                    <TextBlackPage>
                        4.1 If you not planning using your account and QUICKPOWER Network services, you can delete all
                        your personal data by log in on your mobile phone in application QuickPower, Profile and press
                        red button Delete Account. After confirmation and Delete within 7 working day our support will
                        connect with you and return unused amount from your balance on the Master Card/Visa you used to
                        replenish your balance.<br/>
                        4.2 If you have any trouble during online payment in mobile application QuickPower you can call
                        support on the tel. +380669252283 Ukraine/Kyiv.<br/>
                        4.3 Charging card connect to your mobile account. You can’t give it to third person, in the
                        event of the loss of the charging card the user should immediately notify QUICKPOWER by email
                        (quickpowerua@gmail.com) or calling +380669252283.<br/>
                    </TextBlackPage>

                    <SpecificationTextPage>
                        5. Operation & use
                    </SpecificationTextPage>
                    <TextBlackPage>
                        5.1 To register in mobile application you need enter your telephone number, receive text message
                        with a code, read and accept Terms & Condition.<br/>
                        5.2 To start first charging you must replenish your balance higher than zero. For that go to
                        menu Balance / Replenish and use your bank card.<br/>
                        5.3 Charge session<br/>
                        &nbsp;&nbsp;5.3.1. The registered client inserts the cable connector of the required port into
                        his electric car.<br/>
                        If the charging connector required by the Customer is already inserted into the port of the
                        Electric Vehicle of another Client, its use is permitted only after the completion of the
                        Charging session of the Electric Vehicle of the previous Client, who had already started the
                        Charging Session. The status of the Charging session that has already been started can be seen
                        on the Charging Station screen.
                        You should know that stations equipped with several fast charging ports, CHAdeMO and CCS (Combo
                        2), only one fast charging port can be used at a time. At the same time, the use of the Type 2
                        AC charging port can be used regardless of whether the CHAdeMO or CCS fast charging ports are
                        occupied.<br/>
                        &nbsp;&nbsp;5.3.2. The mobile application shows the charging station number and name, the
                        customer chooses the charging port, presses "Charge ".<br/>
                        &nbsp;&nbsp;5.3.3. If the volume of prepaid services on the Client's account is higher than
                        zero, charging session begins.<br/>
                        &nbsp;&nbsp;5.3.4. The client starts the Charging session by clicking on the corresponding
                        button.<br/>
                        &nbsp;&nbsp;5.3.5. Charge session volume is measured in kWh.<br/>
                        &nbsp;&nbsp;5.3.6. Customer can complete the Charge Session as follows:<br/>
                        &nbsp;&nbsp;&nbsp;- Wait for the session to end<br/>
                        &nbsp;&nbsp;&nbsp;- Click on the session interrupt button on the Charging Status screen in the
                        Mobile Application
                        in the Charge menu.<br/>
                        &nbsp;&nbsp;5.3.7. In an emergency, press the red-charge Charging Surcharge emergency button on
                        the front of the Charging Station. Using the Emergency Exit button of the Charging Session is
                        only permitted in an emergency and is forbidden for any other reason.<br/>
                        &nbsp;&nbsp;5.3.8. Upon completion of the charging session, the Client must wait for the
                        automatic unlock of the port and pull out the connector from the port of the electric
                        vehicle.<br/>
                        &nbsp;&nbsp;5.3.9. Reducing the amount of prepaid Services from the Customer Account of the
                        Registered Customer occurs after the end of the Charge Session.<br/>
                    </TextBlackPage>

                    <SpecificationTextPage>
                        6. Liability
                    </SpecificationTextPage>
                    <TextBlackPage>
                        6.1 QUICKPOWER assumes no liability for the continuous availability of the QuickPower app and
                        the website and in particular for the availability of the charging stations and operability of
                        the charging stations and mobile end devices.<br/>
                        6.2 This does not apply in the event of harm to life, body or health.<br/>
                        6.3 Declaration regarding the assumption of liability for refunds, returns, return debit and
                        consumers:
                        “We assume liability for refunds, returns, return debits and contact with the consumer in
                        relation to the payment service and non-functioning apps.
                        On the other hand, technical problems with the charging station should be reported to the
                        operator of the corresponding charging station (with its contact number clearly indicated). We
                        assume no liability for this.”
                    </TextBlackPage>

                    <SpecificationTextPage>
                        7. Cancellation instruction
                    </SpecificationTextPage>
                    <TextBlackPage>
                        You can cancel your contractual agreement in text form (e.g. letter, fax, email) within 14 days
                        without indicating your reasons. The period begins after the receipt of this instruction in text
                        form but not before the conclusion of the contract. The timely dispatch of the cancellation is
                        sufficient in order to observe the cancellation period. The cancellation should be sent to:<br/>
                        <br/>
                        QuickPower<br/>
                        LLC «МОНОЛІТ-17»<br/>
                        ЄДРПОУ 41282654<br/>
                        м. Київ, вул. Цілинна, б.4А, оф. 6, 03131<br/>
                        Tel.: +38(066)925-22-83<br/>
                        E-Mail: quickpowerua@gmail.com<br/>
                    </TextBlackPage>

                    <SpecificationTextPage>
                        8. Data protection
                    </SpecificationTextPage>
                    <TextBlackPage>
                        8.1 QUICKPOWER or commissioned service providers collect, process and use data pertaining to the
                        user for the implementation of the contractual relationship pursuant to the provisions of the
                        Federal Data Protection Act. Personal data is used exclusively in order to inform the customer
                        about new products and services from QUICKPOWER. Data that is not of a personal nature is used
                        for the purposes of market- and public opinion research conducted by QUICKPOWER.<br/>
                        8.2 The users’ identification numbers are sent to the charging station operator in order to
                        activate the charging stations.<br/>
                    </TextBlackPage>

                    <SpecificationTextPage>
                        9. Applicable law & jurisdiction
                    </SpecificationTextPage>
                    <TextBlackPage>
                        The contractual relationship is governed by the law of the Ukraine. The exclusive place of
                        jurisdiction for all disputes.<br/>
                    </TextBlackPage>

                    <SpecificationTextPage>
                        10. Severability clause
                    </SpecificationTextPage>
                    <TextBlackPage>
                        If some contractual provisions are invalid, then the contract shall otherwise remain in force.
                        The contractual parties are obliged to agree in writing the replacement of the invalid provision
                        with the provision that they would have agreed, in good faith and in accordance with legal
                        provisions, had they been aware of the provision’s invalidity.<br/>
                        <br/>
                        QuickPower<br/>
                        LLC «МОНОЛІТ-17»<br/>
                        ЄДРПОУ 41282654<br/>
                        м. Київ, вул. Цілинна, б.4А, оф. 6, 03131<br/>
                        Tel.: +38(066)925-22-83<br/>
                        E-Mail: quickpowerua@gmail.com<br/>
                        Web: www.quickpower.net<br/>
                    </TextBlackPage>

                </Container>
            </BlockWrapper>
        )
    }
};

export default TermsAndConditionsPage
